<template>
  <div class="to-left">
    <h1 v-if="project">
      Créer un nouveau type de signalement pour le projet «
      {{ project.title }} » depuis le catalogue Datasud
    </h1>
    Liste des ressources géographiques publiées par vos organisations :
    <div class="table">
      <div class="row header">
        <div>Organisation</div>
        <div>Dataset</div>
        <div>Ressource</div>
      </div>
      <div v-if="resources && resources.length > 0">
        <div
          v-for="(resource, index) in paginatedResources"
          :key="`${resource.resource_name}-${index}`"
          :class="[
            'row',
            {
              selected:
                selectedResource && resource.layer === selectedResource.layer,
            },
          ]"
          @click="selectResource(resource)"
        >
          <div>{{ resource.organization_name }}</div>
          <div>{{ resource.dataset_name }}</div>
          <div>{{ resource.resource_name }}</div>
        </div>
      </div>
      <div
        v-else
        class="no-response"
      >
        Pas de données trouvées pour l'utilisateur {{ user.username }}
      </div>
    </div>

    <div class="pagination_wrapper">
      <div
        v-if="nbPages.length > 1"
        id="table-features_info"
        class="dataTables_info"
      >
        Affichage de l'élément {{ pagination.start + 1 }} à
        {{ displayedPageEnd }}
        sur {{ resources.length }} éléments
      </div>
      <div
        v-if="nbPages.length > 1"
        id="table-features_paginate"
        class="dataTables_paginate paging_simple_numbers"
      >
        <a
          id="table-features_previous"
          :class="[
            'paginate_button previous',
            { disabled: pagination.currentPage === 1 },
          ]"
          @click="toPreviousPage"
        >Précédent</a>
        <span>
          <a
            v-for="pageNumber in nbPages"
            :key="'page' + pageNumber"
            :class="[
              'paginate_button',
              { current: pageNumber === pagination.currentPage },
            ]"
            @click="toPage(pageNumber)"
          >{{ pageNumber }}</a>
        </span>
        <!-- // TODO : <span v-if="nbPages > 4" class="ellipsis">...</span> -->
        <a
          id="table-features_next"
          :class="[
            'paginate_button next',
            { disabled: pagination.currentPage === nbPages.length },
          ]"
          @click="toNextPage"
        >Suivant</a>
      </div>
    </div>

    <div class="import">
      <button
        :disabled="!selectedResource"
        class="ui fluid teal icon button"
        @click="launchImport"
      >
        <i
          class="upload icon"
          aria-hidden="true"
        /> Lancer l'import avec le fichier
        <span v-if="selectedResource">
          {{ selectedResource.resource }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import miscAPI from '@/services/misc-api';

export default {
  name: 'Catalog',

  data() {
    return {
      resources: [],
      pagination: {
        currentPage: 1,
        pagesize: 15,
        start: 0,
        end: 15,
      },
      selectedResource: null,
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapGetters('projects', ['permissions']),
    ...mapState('projects', ['project']),

    paginatedResources() {
      return this.resources.slice(this.pagination.start, this.pagination.end);
    },

    nbPages() {
      const N = Math.ceil(this.resources.length / this.pagination.pagesize);
      const arr = [...Array(N).keys()].map(function (x) {
        ++x;
        return x;
      });
      return arr;
    },

    displayedPageEnd() {
      return this.resources.length <= this.pagination.end
        ? this.resources.length
        : this.pagination.end;
    },
  },

  mounted() {
    this.$store.commit('DISPLAY_LOADER', 'Interrogation du catologue datasud.');
    this.$store.dispatch('projects/GET_PROJECT', this.$route.params.slug);
    miscAPI.getIdgoCatalog(this.user.username).then((data) => {
      if (data && data.layers) {
        this.resources = data.layers;
      }
      this.$store.commit('DISCARD_LOADER');
    });
  },

  methods: {
    selectResource(resource) {
      this.selectedResource = resource;
    },
    toPage(pageNumber) {
      const toAddOrRemove =
        (pageNumber - this.pagination.currentPage) * this.pagination.pagesize;
      this.pagination.start += toAddOrRemove;
      this.pagination.end += toAddOrRemove;
      this.pagination.currentPage = pageNumber;
    },

    toPreviousPage() {
      if (this.pagination.start > 0) {
        this.pagination.start -= this.pagination.pagesize;
        this.pagination.end -= this.pagination.pagesize;
        this.pagination.currentPage -= 1;
      }
    },

    toNextPage() {
      if (this.pagination.end < this.resources.length) {
        this.pagination.start += this.pagination.pagesize;
        this.pagination.end += this.pagination.pagesize;
        this.pagination.currentPage += 1;
      }
    },

    redirect(geojson) {
      const name =
        this.$route.params.feature_type_slug === 'create'
          ? 'ajouter-type-signalement'
          : 'details-type-signalement';
      this.$router.push({
        name: name,
        params: {
          geojson,
          type: 'external-geojson',
        },
      });
    },

    launchImport() {
      const queryParams = `typename=${this.selectedResource.layer}&organization_slug=${this.selectedResource.organization_slug}`;
      miscAPI.getExternalGeojson(queryParams).then((data) => {
        if (data) {
          this.redirect(data);
        }
      });
    },
  },
};
</script>

<style scoped>
.to-left {
  text-align: left;
}

h1 {
  margin: 0.5em 0;
}

.table {
  width: 100%;
  border: 1px solid #c0c0c0;
  margin: 2rem 0;
}
.table .row {
  display: flex;
  transition: all ease-out 0.2s;
}
.table .row:not(.header).selected {
  background-color: #8bddd9;
}
.table .row:not(.header):hover {
  background-color: #009c95;
  color: #ffffff;
  cursor: pointer;
}
.table .row:not(:last-child) {
  border-bottom: 1px solid #cacaca;
}
.table .row > div {
  width: 100%;
  padding: 0.5rem;
}
.table .header {
  background-color: #e0e0e0;
}

.no-response {
  padding: 1rem;
  text-align: center;
  color: #585858;
}

.import {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

/* datatables */
.pagination_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pagination_wrapper > div {
  margin: 0.25em 0;
}
@media only screen and (max-width: 767px) {
  .pagination_wrapper {
    justify-content: center;
  }
}

.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_processing,
.dataTables_paginate {
  color: #333;
}
/* .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
} */
/* .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
} */
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff),
    color-stop(100%, #dcdcdc)
  );
  background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
}
.dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}
.dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #111)
  );
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}
</style>