import axios from '@/axios-client.js';
import store from '../store';


const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;

const miscAPI = {

  async getIdgoCatalog(username) {
    try {
      const response = await axios.get(
        `${baseUrl}idgo-catalog/?user=${username}`
      );
      if (
        response.status === 200 &&
        response.data
      ) {
        return response.data;
      } else {
        return null;
      }
    } catch (err) {
      return err;
    }
  },

  async getExternalGeojson(queryParams) {
    const response = await axios.get(
      `${baseUrl}external-geojson/${queryParams ? '?' + queryParams : ''}`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getUserEvents(project_slug) {
    const response = await axios.get(`${baseUrl}events/${project_slug ? '?project_slug=' + project_slug : ''}`);
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },
};

export default miscAPI;
