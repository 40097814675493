var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"to-left"},[(_vm.project)?_c('h1',[_vm._v(" Créer un nouveau type de signalement pour le projet « "+_vm._s(_vm.project.title)+" » depuis le catalogue Datasud ")]):_vm._e(),_vm._v(" Liste des ressources géographiques publiées par vos organisations : "),_c('div',{staticClass:"table"},[_vm._m(0),(_vm.resources && _vm.resources.length > 0)?_c('div',_vm._l((_vm.paginatedResources),function(resource,index){return _c('div',{key:`${resource.resource_name}-${index}`,class:[
          'row',
          {
            selected:
              _vm.selectedResource && resource.layer === _vm.selectedResource.layer,
          },
        ],on:{"click":function($event){return _vm.selectResource(resource)}}},[_c('div',[_vm._v(_vm._s(resource.organization_name))]),_c('div',[_vm._v(_vm._s(resource.dataset_name))]),_c('div',[_vm._v(_vm._s(resource.resource_name))])])}),0):_c('div',{staticClass:"no-response"},[_vm._v(" Pas de données trouvées pour l'utilisateur "+_vm._s(_vm.user.username)+" ")])]),_c('div',{staticClass:"pagination_wrapper"},[(_vm.nbPages.length > 1)?_c('div',{staticClass:"dataTables_info",attrs:{"id":"table-features_info"}},[_vm._v(" Affichage de l'élément "+_vm._s(_vm.pagination.start + 1)+" à "+_vm._s(_vm.displayedPageEnd)+" sur "+_vm._s(_vm.resources.length)+" éléments ")]):_vm._e(),(_vm.nbPages.length > 1)?_c('div',{staticClass:"dataTables_paginate paging_simple_numbers",attrs:{"id":"table-features_paginate"}},[_c('a',{class:[
          'paginate_button previous',
          { disabled: _vm.pagination.currentPage === 1 },
        ],attrs:{"id":"table-features_previous"},on:{"click":_vm.toPreviousPage}},[_vm._v("Précédent")]),_c('span',_vm._l((_vm.nbPages),function(pageNumber){return _c('a',{key:'page' + pageNumber,class:[
            'paginate_button',
            { current: pageNumber === _vm.pagination.currentPage },
          ],on:{"click":function($event){return _vm.toPage(pageNumber)}}},[_vm._v(_vm._s(pageNumber))])}),0),_c('a',{class:[
          'paginate_button next',
          { disabled: _vm.pagination.currentPage === _vm.nbPages.length },
        ],attrs:{"id":"table-features_next"},on:{"click":_vm.toNextPage}},[_vm._v("Suivant")])]):_vm._e()]),_c('div',{staticClass:"import"},[_c('button',{staticClass:"ui fluid teal icon button",attrs:{"disabled":!_vm.selectedResource},on:{"click":_vm.launchImport}},[_c('i',{staticClass:"upload icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Lancer l'import avec le fichier "),(_vm.selectedResource)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedResource.resource)+" ")]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row header"},[_c('div',[_vm._v("Organisation")]),_c('div',[_vm._v("Dataset")]),_c('div',[_vm._v("Ressource")])])
}]

export { render, staticRenderFns }